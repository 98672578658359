import React, { useState, useEffect, useRef } from "react"
import "./TechnologyStack.css"
import Angular from "../../src/assets/img/angular.png"
import Rct from "../../src/assets/img/React-icon.svg.png"
import Node from "../../src/assets/img/node.svg.png"
import IOS from "../../src/assets/img/ios.png"
import Android from "../../src/assets/img/android.png"
// import Kotlin from "../../src/assets/img/Kotlin.png"
import AWS from "../../src/assets/img/aws.png"
import Cloud from "../../src/assets/img/googlecloud.png"
// import Mongo from "../../src/assets/img/mongo.png"
// import Mysql from "../../src/assets/img/mysql.png"
import Vue from "../../src/assets/img/vue.svg.png"
import Php from "../../src/assets/img/php.svg.png"
import Java from "../../src/assets/img/java.png"
import Flt from "../../src/assets/img/flutter.svg"
// import Native from "../../src/assets/img/reactnative.svg"
import Azure from "../../src/assets/img/azure.png"
import NVDA from "../../src/assets/img/nvda.png"
import JAWS from "../../src/assets/img/Jaws.png"
import Voice from "../../src/assets/img/voiceover.svg"
import Talk from "../../src/assets/img/talkback.jpg"
import Python from "../../src/assets/img/python.webp"
import JS from "../../src/assets/img/Js.png"
import Selenium from "../../src/assets/img/selenium.svg"
import Play from "../../src/assets/img/playwright.png"

const technologies = [
  {
    id: 0,
    techName: "Web Development",
    cards: [
      {
        img: Angular,
        name: "Angular",
      },
      {
        img: Rct,
        name: "React",
      },
      {
        img: Vue,
        name: "Vue",
      },
      {
        img: Node,
        name: "Node",
      },
      {
        img: Php,
        name: "PhP",
      },
      {
        img: Java,
        name: "Java",
      },
    ],
  },
  {
    id: 1,
    techName: "Mobile",
    cards: [
      {
        img: IOS,
        name: "IOS",
      },
      {
        img: Android,
        name: "Android",
      },
      {
        img: Rct,
        name: "React Native",
      },
      {
        img: Flt,
        name: "Flutter",
      },
    ],
  },
  {
    id: 2,
    techName: "DevOps",
    cards: [
      {
        img: AWS,
        name: "AWS",
      },
      {
        img: Cloud,
        name: "Google Cloud",
      },
      {
        img: Azure,
        name: "Azure",
      },
    ],
  },
  //   {
  //     id: 3,
  //     techName: "Database",
  //     cards: [
  //       {
  //         img: Mongo,
  //         name: "Mongo",
  //       },
  //       {
  //         img: Mysql,
  //         name: "MySQl",
  //       },
  //     ],
  //   },
  {
    id: 4,
    techName: "Accessibility",
    cards: [
      {
        img: JAWS,
        name: "JAWS",
      },
      {
        img: NVDA,
        name: "NVDA",
      },
      {
        img: Voice,
        name: "Voiceover",
      },
      {
        img: Talk,
        name: "Talkback",
      },
    ],
  },
  {
    id: 5,
    techName: "Automation",
    cards: [
      {
        img: Selenium,
        name: "Selenium",
      },
      {
        img: Play,
        name: "Playwright",
      },
      {
        img: JS,
        name: "JavaScript",
      },
      {
        img: Java,
        name: "Java",
      },
      {
        img: Python,
        name: "Python",
      },
    ],
  },
]

const TechnologyStack = () => {
  const [techId, setTechId] = useState(0)
  const [cards, setCards] = useState([
    {
      img: Angular,
      name: "Angular",
    },
    {
      img: Rct,
      name: "React",
    },
    {
      img: Vue,
      name: "Vue",
    },
    {
      img: Node,
      name: "Node",
    },
    {
      img: Php,
      name: "PhP",
    },
    {
      img: Java,
      name: "Java",
    },
  ])

  const buttonHandler = (id, cards) => {
    setTechId(id)
    setCards(cards)
  }

  const tabListRef = useRef(null)

  useEffect(() => {
    const handleKeyDown = event => {
      const tabs = tabListRef.current.querySelectorAll('[role="tab"]')
      let index = Array.prototype.indexOf.call(tabs, document.activeElement)

      if (event.key === "ArrowRight") {
        event.preventDefault()
        index = index + 1 < tabs.length ? index + 1 : 0
        tabs[index].focus()
        buttonHandler(technologies[index].id, technologies[index].cards)
      } else if (event.key === "ArrowLeft") {
        event.preventDefault()
        index = index - 1 >= 0 ? index - 1 : tabs.length - 1
        tabs[index].focus()
        buttonHandler(technologies[index].id, technologies[index].cards)
      }
    }

    const tabList = tabListRef.current
    tabList.addEventListener("keydown", handleKeyDown)

    return () => {
      tabList.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <div className="techstack">
      <div className="heading">
        <h2 className="font-weight-bold" id="tablist-tech-stack">
          Technology Stack we work with{" "}
        </h2>
      </div>
      <div className="technology-stack-container">
        <div
          className="button-container"
          role="tablist"
          aria-labelledby="tablist-tech-stack"
          ref={tabListRef}
        >
          {technologies.map(tech => (
            <button
              className={`button ${techId === tech.id ? "active" : ""}`}
              onClick={() => buttonHandler(tech.id, tech.cards)}
              key={tech.id}
              type="button"
              role="tab"
              aria-selected={techId === tech.id}
              aria-controls={`tech-panel-${tech.id}`}
              id={`tech-tab-${tech.id}`}
              tabIndex={techId === tech.id ? 0 : -1}
            >
              <span className="techname">{tech.techName}</span>
            </button>
          ))}
        </div>
        <div
          id={`tech-panel-${techId}`}
          role="tabpanel"
          aria-labelledby={`tech-tab-${techId}`}
        >
          <div className="card-container" role="list">
            {cards.map(card => (
              <div className="technology-card" key={card.name} role="listitem">
                <div>
                  <img src={card.img} alt={""} />
                </div>
                <div className="cardname">{card.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TechnologyStack
